import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/pages/LandingPage';
import SelectContinent from './components/pages/SelectContinent';
import AfricaPage from './components/pages/country_pages/AfricaPage';
import AsiaPage from './components/pages/country_pages/AsiaPage';
import EuropePage from './components/pages/country_pages/EuropePage';
import NorthAmericaPage from './components/pages/country_pages/NorthAmericaPage';
import SouthAmericaPage from './components/pages/country_pages/SouthAmericaPage';
import AustraliaPage from './components/pages/country_pages/AustraliaPage';
import FoodServicesPage from './components/pages/country_pages/FoodServicesPage';
import LocalBusinessesPage from './components/pages/country_pages/LocalBusinessesPage';
import HairServicesPage from './components/pages/country_pages/HairServicesPage';
import OtherServicesPage from './components/pages/country_pages/OtherServicesPage';
import EnterLocationPage from './components/pages/EnterLocationPage';
import { Analytics } from "@vercel/analytics/react"
import Login from './components/pages/Login';
import SignUp from './components/pages/SignUp';
import ServiceDetailPage from './components/pages/ServiceDetailPage';
import CartPage from './components/pages/CartPage';
import BecomeServiceProvider from './components/pages/BecomeServiceProvider';
import ServiceSubmit from './components/pages/ServiceSubmit';
import UserProfile from './components/pages/UserProfile';
import SubmissionSuccess from './components/pages/SubmissionSuccess';
import ScrollToTop from './components/ScrollToTop';


function App() {
  return (
    <Router>
      <ScrollToTop />
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/enter-location" element={<EnterLocationPage />} />
        <Route path="/select-continent" element={<SelectContinent />} />
        <Route path="/continent/africa" element={<AfricaPage />} />
        <Route path="/continent/asia" element={<AsiaPage />} />
        <Route path="/continent/europe" element={<EuropePage />} />
        <Route path="/continent/north-america" element={<NorthAmericaPage />} />
        <Route path="/continent/south-america" element={<SouthAmericaPage />} />
        <Route path="/continent/australia" element={<AustraliaPage />} />
        <Route path="/food-services/:continent" element={<FoodServicesPage />} />
        <Route path="/local-businesses/:continent" element={<LocalBusinessesPage />} />
        <Route path="/hair-services/:continent" element={<HairServicesPage />} />
        <Route path="/other-services/:continent" element={<OtherServicesPage />} />
        <Route path="/service/:serviceId" element={<ServiceDetailPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path='/become-service-provider' element={<BecomeServiceProvider />} />
        <Route path='/service-submit' element={<ServiceSubmit />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/submission-success" element={<SubmissionSuccess />} />
      </Routes>
      <Analytics />
    </div>
    </Router>
  );
}

export default App;

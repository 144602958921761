import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase storage imports
import './BecomeServiceProvider.css';

const BecomeServiceProvider = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    businessName: '',
    category: '',
    otherCategory: '',
    city: '',
    country: '',
    serviceDescription: '',
    website: '',
    location: '',
    orderMethods: [],
    logo: null,
    banner: null,
    additionalImages: [],
    servicesAndPrices: [],
  });

    // Upload images to Firebase Storage and return URLs
    const uploadImages = async () => {
      const uploadFile = async (file, folder) => {
        const storageRef = ref(getStorage, `${folder}/${file.name}`);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
      };
  
      const urls = {};
      if (formData.logo) urls.logoUrl = await uploadFile(formData.logo, "logos");
      if (formData.banner) urls.bannerUrl = await uploadFile(formData.banner, "banners");
      
      const additionalImageUrls = await Promise.all(
        formData.additionalImages.map((img) => uploadFile(img, "additional-images"))
      );
      urls.additionalImageUrls = additionalImageUrls;
  
      return urls;
    };

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const totalSteps = 6;

  const validateStep = () => {
    let validationErrors = {};
    if (step === 1) {
      if (!formData.name) validationErrors.name = 'Name is required';
      if (!formData.email) validationErrors.email = 'Email is required';
    }
    if (step === 2) {
      if (!formData.businessName) validationErrors.businessName = 'Business name is required';
      if (!formData.category) validationErrors.category = 'Category is required';
      if (formData.category === 'Other' && !formData.otherCategory) validationErrors.otherCategory = 'Specify other category';
      if (!formData.serviceDescription) validationErrors.serviceDescription = 'Service description is required';
    }
    if (step === 3) {
      if (!formData.city) validationErrors.city = 'City is required';
      if (!formData.country) validationErrors.country = 'Country is required';
    }
    if (step === 4) {
      if (formData.orderMethods.length === 0) validationErrors.orderMethods = 'Please add at least one order method';
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to add a new order method field
  const addOrderMethod = () => {
    setFormData({
      ...formData,
      orderMethods: [...formData.orderMethods, { method: '', details: '' }],
    });
  };

  // Function to handle changes in each order method's fields
  const handleOrderMethodChange = (index, field, value) => {
    const updatedOrderMethods = formData.orderMethods.map((orderMethod, i) => 
      i === index ? { ...orderMethod, [field]: value } : orderMethod
    );
    setFormData({ ...formData, orderMethods: updatedOrderMethods });
  };

  // Function to remove an order method
  const removeOrderMethod = (index) => {
    const updatedOrderMethods = formData.orderMethods.filter((_, i) => i !== index);
    setFormData({ ...formData, orderMethods: updatedOrderMethods });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleAdditionalImagesChange = (e) => {
    const { files } = e.target;
    setFormData({ ...formData, additionalImages: Array.from(files) });
  };

  const handleAddService = () => {
    setFormData({
      ...formData,
      servicesAndPrices: [...formData.servicesAndPrices, { name: '', price: '' }],
    });
  };

  const handleServiceChange = (index, field, value) => {
    const updatedServices = formData.servicesAndPrices.map((service, i) =>
      i === index ? { ...service, [field]: value } : service
    );
    setFormData({ ...formData, servicesAndPrices: updatedServices });
  };

  const removeService = (index) => {
    const updatedServices = formData.servicesAndPrices.filter((_, i) => i !== index);
    setFormData({ ...formData, servicesAndPrices: updatedServices });
  };

  const nextStep = () => {
    if (validateStep()) {
      setStep(step + 1);
      window.scrollTo(0, 0);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep()) {
      const templateParams = {
        name: formData.name,
        email: formData.email,
        businessName: formData.businessName,
        category: formData.category === 'Other' ? formData.otherCategory : formData.category,
        city: formData.city,
        country: formData.country,
        serviceDescription: formData.serviceDescription,
        website: formData.website,
        location: formData.location,
        orderMethods: formData.orderMethods.map((om) => `${om.method}: ${om.details}`).join(', '),
      };

      emailjs.send('service_moljiqm', 'template_pch9mg8', templateParams, 'op_sPUaDGFLLXs5Ei')
        .then((response) => {
          alert('Request submitted successfully!');
          navigate('/submission-success');
        })
        .catch((error) => {
          console.error('Failed to send request:', error);
        });
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="form-section">
            <h3>Personal Information</h3>
            <label>
              Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your full name"
                required
              />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email address"
                required
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </label>
            <button type="button" className="next-button" onClick={nextStep}>Next</button>
          </div>
        );
      case 2:
        return (
          <div className="form-section">
            <h3>Business Information</h3>
            <label>
              Business Name:
              <input
                type="text"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                placeholder="Enter your business name"
                required
              />
              {errors.businessName && <span className="error-message">{errors.businessName}</span>}
            </label>
            <label>
              Category:
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="">Select a category</option>
                <option value="Food">Food</option>
                <option value="Hair">Hair</option>
                <option value="Local Businesses">Local Businesses</option>
                <option value="Other">Other</option>
              </select>
              {errors.category && <span className="error-message">{errors.category}</span>}
            </label>
            {formData.category === 'Other' && (
              <label>
                Please specify:
                <input
                  type="text"
                  name="otherCategory"
                  value={formData.otherCategory}
                  onChange={handleChange}
                  placeholder="Specify your business category"
                  required
                />
                {errors.otherCategory && <span className="error-message">{errors.otherCategory}</span>}
              </label>
            )}
            <label>
              Service Description:
              <textarea
                name="serviceDescription"
                value={formData.serviceDescription}
                onChange={handleChange}
                placeholder="Describe the services your business provides"
                required
              />
              {errors.serviceDescription && <span className="error-message">{errors.serviceDescription}</span>}
            </label>
            <div className="navigation-buttons">
              <button type="button" className="prev-button" onClick={prevStep}>Back</button>
              <button type="button" className="next-button" onClick={nextStep}>Next</button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="form-section">
            <h3>Location Information</h3>
            <label>
              City:
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="Enter the city your business is located in"
                required
              />
              {errors.city && <span className="error-message">{errors.city}</span>}
            </label>
            <label>
              Country:
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="Enter the country your business is located in"
                required
              />
              {errors.country && <span className="error-message">{errors.country}</span>}
            </label>
            <label>
              Location/Address (Optional):
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Enter the physical address of your business (if applicable)"
              />
            </label>
            <div className="navigation-buttons">
              <button type="button" className="prev-button" onClick={prevStep}>Back</button>
              <button type="button" className="next-button" onClick={nextStep}>Next</button>
            </div>
          </div>
        );
        case 4:
          return (
            <div className="form-section">
              <h3>Additional Details</h3>
  
              {formData.orderMethods.map((orderMethod, index) => (
                <div key={index} className="order-method-section">
                  <label>
                    Preferred Order Method:
                    <select
                      value={orderMethod.method}
                      onChange={(e) => handleOrderMethodChange(index, 'method', e.target.value)}
                      required
                    >
                      <option value="">Select an order method</option>
                      <option value="Website">Website</option>
                      <option value="Social Media">Social Media</option>
                      <option value="Mobile">Mobile</option>
                    </select>
                  </label>
                  
                  {orderMethod.method === 'Website' && (
                    <label>
                      Website URL:
                      <input
                        type="url"
                        value={orderMethod.details}
                        onChange={(e) => handleOrderMethodChange(index, 'details', e.target.value)}
                        placeholder="Enter website URL"
                        required
                      />
                    </label>
                  )}
                  
                  {orderMethod.method === 'Social Media' && (
                    <label>
                      Social Media Platform and Username:
                      <input
                        type="text"
                        value={orderMethod.details}
                        onChange={(e) => handleOrderMethodChange(index, 'details', e.target.value)}
                        placeholder="e.g., Instagram @username"
                        required
                      />
                    </label>
                  )}
                  
                  {orderMethod.method === 'Mobile' && (
                    <label>
                      Mobile Number:
                      <input
                        type="tel"
                        value={orderMethod.details}
                        onChange={(e) => handleOrderMethodChange(index, 'details', e.target.value)}
                        placeholder="Enter mobile number"
                        required
                      />
                    </label>
                  )}
  
                  {/* Remove Button */}
                  <button type="button" className="remove-order-method" onClick={() => removeOrderMethod(index)}>
                    Remove
                  </button>
                </div>
              ))}
  
              <button type="button" onClick={addOrderMethod} className="add-order-method-button">
                + Add Another Order Method
              </button>
  
              {errors.orderMethods && <span className="error-message">{errors.orderMethods}</span>}
  
              <div className="navigation-buttons">
                <button type="button" className="prev-button" onClick={prevStep}>Back</button>
                <button type="button" className="next-button" onClick={nextStep}>Next</button>
            </div>
            </div>
          );
          case 5:
        return (
          <div className="form-section">
            <h3>Business Images</h3>
            <label>
              Logo:
              <input type="file" name="logo" onChange={handleFileChange} />
            </label>
            <label>
              Banner:
              <input type="file" name="banner" onChange={handleFileChange} />
            </label>
            <label>
              Additional Images:
              <input type="file" name="additionalImages" multiple onChange={handleAdditionalImagesChange} />
            </label>
            <div className="navigation-buttons">
              <button type="button" className="prev-button" onClick={prevStep}>Back</button>
              <button type="button" className="next-button" onClick={nextStep}>Next</button>
            </div>
          </div>
        );
      case 6:
        return (
          <div className="form-section">
            <h3>Services and Pricing</h3>
            {formData.servicesAndPrices.map((service, index) => (
              <div key={index} className="service-price-section">
                <label>
                  Service Name:
                  <input
                    type="text"
                    value={service.name}
                    onChange={(e) => handleServiceChange(index, 'name', e.target.value)}
                    placeholder="Enter service name"
                  />
                </label>
                <label>
                  Price:
                  <input
                    type="number"
                    value={service.price}
                    onChange={(e) => handleServiceChange(index, 'price', e.target.value)}
                    placeholder="Enter price"
                  />
                </label>
                <button type="button" onClick={() => removeService(index)} className="remove-service-button">
                  Remove
                </button>
              </div>
            ))}
            <button type="button" onClick={handleAddService} className="add-service-button">
              + Add Service
            </button>
            <div className="navigation-buttons">
              <button type="button" className="prev-button" onClick={prevStep}>Back</button>
              <button type="submit" className="submit-button">Submit</button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const ProgressBar = ({ step, totalSteps }) => {
    const percentage = (step / totalSteps) * 100;

    return (
      <div className="progress-bar">
        <div className="progress-bar-fill" style={{ width: `${percentage}%` }} />
        <span className="progress-bar-text">Step {step} of {totalSteps}</span>
      </div>
    );
  };

  return (
    <div className="service-provider-form">
      <h1>Become a Service Provider</h1>
      <ProgressBar step={step} totalSteps={totalSteps} />
      <form onSubmit={handleSubmit}>
        {renderStep()}
      </form>
      <button onClick={() => navigate(-1)}>Back Home</button>
    </div>
  );
};

export default BecomeServiceProvider;
